import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Banner from "../components/banner";
import About from "../components/about";
import Service from "../components/service";

import Blogs from "../components/blogs";

const IndexPage = ({ data }) => (
  <Layout header="home">
    <SEO
      title={data.contentfulAboutMe.designation}
      keywords={[`Dijana Pavlovic`, `UX/UI Designer`, `Designer`]}
    />
    <Banner data={data.contentfulAboutMe}></Banner>

    {data.contentfulSiteInformation.menus
      .filter(item => item === "About")
      .map(t => {
        return <About key="About" data={data.contentfulAboutMe}></About>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Kompetenser")
      .map(t => {
        return <Service key="Kompetenser" data={data.allContentfulService}></Service>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Projekt")
      .map(t => {
        return <Blogs key="Projekt" data={data.allContentfulBlogs}></Blogs>;
      })}
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query AboutQuery {
    contentfulAboutMe {
      name
      photo {
        file {
          url
        }
        src: url
      }
      designation
      age
      gmail
      id
      instagram
      linkdin
      location
      description {
        childMarkdownRemark {
          html
        }
      }
      bannerImage {
        src: url
      }
      bannerList
    }
    allContentfulService(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulBlogs(limit: 5, filter: {node_locale: {eq: "en-US"}}, sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          title
          slug
          node_locale
          featureImage {
            src: url
          }
          createdAt
        }
      }
    }
    contentfulSiteInformation {
      menus
    }
  }
`;
